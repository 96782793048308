import React from "react"
import { Link } from "gatsby"

export default () => (
  <p className="p-20 text-center min-h-screen">
    <span className="text-6xl block">404</span>
    NotFound
  </p>

)
